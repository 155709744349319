@import '../styles/variables.scss';

.section-proposal {
    background-color: $light-color;
    span {
        font-size: 20px;
        font-weight: 900;
        color: $primary-color;
        line-height: 22px;
    }
    .color {
        color: $third-color;
    }
}

@media only screen and (min-width: 420px) {
    .section-proposal {
        span {
            font-size: 38px;
            line-height: 80px;
        }
    }
}