@import './variables.scss';
@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100;1,300;1,700&display=swap');
@import 'node_modules/react-modal-video/scss/modal-video.scss';

*, *::before, *::after {
    margin: 0;
    box-sizing: border-box;
    padding: 0;
}

body {
    font-family: $secondary-font;
    background-color: white;
}

.react-tabs__tab {
    padding: 2px 6px !important;
    font-size: 15px !important;
    border-top: 1px solid $secondary-color !important;
    border-right: 1px solid $secondary-color !important;
    border-left: 1px solid $secondary-color !important;
    border-radius: 5px 5px 0 0 !important;
}

.react-tabs__tab--selected {
    background: $secondary-color !important;
    color: $light-color !important;
    font-weight: bold !important;
    border-color: $secondary-color !important;
}

.react-tabs__tab-list {
    border-bottom: 1px solid $secondary-color !important;
}

@media only screen and (min-width: 420px) {
    .react-tabs__tab {
        padding: 6px 12px !important;
        font-size: 18px !important;
    }
}