@import '../../styles/variables.scss';

.section-prefooter {
    background-color: $secondary-color;
    .cont-address {
        display: flex;
        align-items: center;
        .cont-text {
            padding: 50px;
            border: 2px solid $light-color;
            width: 100%;
            margin-top: 30px;
            .title {
                font-weight: bold;
                font-size: 30px;
                color: $light-color;
            }
            .text {
                font-size: 22px;
                color: $light-color;
            }
            .right {
                margin-right: 7px;
            }
        }
    }
}

@media only screen and (min-width: 420px) {
    .section-prefooter {    
        .cont-address {
            align-items: baseline;
            .cont-text {
                margin-left: 50px;
                width: 60%;
            }
        }
    }
}