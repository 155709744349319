@import '../styles/variables.scss';

.section- {
    display: flex;
    background-color: $light-color;
    justify-content: center;
    align-items: center;

    .title-about- {
        font-size: 35px;
        font-weight: bolder;
        color: $secondary-color;
        font-family: $primary-font;
    }
    .text {
        color: $dark-color;
        font-size: 18px;
        line-height: 31px;
    }

    .info {
        color: $secondary-color;
        font-weight: bold;
        font-size: 25px;
        line-height: 40px;
        font-family: $secondary-font;
    }
    .le {
        margin-left: 15px;
    }
}

@media only screen and (min-width: 420px) {
    .section- {
        height: 100vh; 
        .title-about- {
            font-size: 60px;
        }
        .text {
            font-size: 22px;
            line-height: 37px;
        }
    }
}